* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 60px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

details > summary {
  list-style: none;
}

details summary::-webkit-details-marker {
  display:none;
}

.TabelaPrecoPg-dropdown_animado {
  height: 140px;
  animation-name: dropdown_animado;
  animation-duration: 1s;
}

@keyframes dropdown_animado {
  0% {
    height: 120px;
  }

  100% {
    height: 140px;
  }
}

:root {
  --cor-primaria: #143460;
}

/* HOTSITE */

.h-main-container {
  padding-top: 60px;
}

.h-header {
  width: 100%;
  height: 60px;
  border-bottom: 5px solid var(--cor-primaria);
  z-index: 99999;
  position: fixed;
  background-color: white;
}

.h-header-container {
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.h-header-container>img {
  height: 50px;
  object-fit: contain;
  cursor: pointer;
}

.h-header-container>nav {
  display: flex;
  gap: 30px;
  align-items: center;
}

.h-header-container>nav>a {
  font-size: 15px;
  color: var(--cor-primaria);
  text-decoration: none;
}

.h-header-container>nav>button {
  font-size: 20px;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: none;
}

.h-header-container>nav>a:hover {
  text-decoration: underline;
}

.h-slide-img {
  width: 100%;
  max-height: 450px;
  object-fit: cover;
}

.h-sobre { 
  width: 100%;
  padding: 50px 30px;
  background-color: var(--cor-primaria);
  margin-top: -5px;
}

.h-sobre-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.h-sobre-container>div {
  width: 30%;
  padding: 50px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  color: white;
}

.h-sobre-container>h2 {
  font-size: 25px;
  text-decoration: underline;
  text-decoration-color: var(--cor-secundaria);
}

.h-sobre-container>p {
  font-size: 20px;
}

.h-func {
  width: 100%;
}

.h-func-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.h-func-container>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.h-func-container>div>img {
  width: 350px;
  z-index: 99;
  border-radius: 500px;
  object-fit: cover;
  /* background-color: lightgray; */
  /* box-shadow: 0 5px 5px rgba(0,0,0,0.5) */
}

.h-func-container>div>img:nth-child(1) {
  margin-bottom: -30px;
}

.h-func-container>div>img:nth-child(2) {
  margin-top: -30px;
}

.h-func-container>div>p {
  width: 50%;
  font-size: 25px;
}

.h-func-container>div:nth-child(1)>p {
  text-align: left;
}

.h-func-container>div:nth-child(2)>p {
  text-align: center;
  margin: 0 auto;
}

.h-func-container>div:nth-child(3)>p {
  text-align: right;
}

.h-depo {
  width: 100%;
  background-color: var(--cor-primaria);
  padding: 50px 20px;  
}

.h-depo-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.h-depo-container>div {
  width: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  gap: 15px;
}

.h-depo-container>div>img {
  width: 100px;
  height: 100px;
  border-radius: 500px;
}

.h-depo-container>div>div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.h-depo-container>div>div>h2 {
  font-size: 20px;
}

.h-depo-container>div>div>p {
  font-size: 17px;
}

.h-faq-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.h-plataforma {
  width: 100%;
}

.h-plataforma-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  text-align: center;
}

.h-plataforma-container>div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
}

.h-plataforma-container>div>img {
  width: 100px;
  height: 100px;
  border-radius: 500px;
  padding: 10px;
  object-fit: contain;
  cursor: pointer;
}

.h-plataforma-container>div>img:nth-child(1) {
  background-color: #f08d44;
}

.h-plataforma-container>div>img:nth-child(2) {
  background-color: #9e3bc2;
}

.h-plataforma-container>div>img:nth-child(3) {
  background-color: #28373e;
}

.h-plataforma-container>div>img:nth-child(4) {
  background-color: #2196f3;
}

.h-plataforma-container>div>img:nth-child(5) {
  background-color: #00907a;
}

.h-plataforma-container>div>img:nth-child(6) {
  background-color: #097d86;
}

.h-plataforma-container>div>img:nth-child(7) {
  background-color: #000000;
}

.h-plataforma-container>div>img:nth-child(8) {
  background-color: #000000;
}

.h-plataforma-container>div>img:nth-child(9) {
  background-color: #00a82d;
}

.h-plataforma-container>div>img:nth-child(10) {
  background-color: #f57e2c;
}

.h-plataforma-container>div>img:nth-child(11) {
  background-color: #8f1a24;
}

.h-slogan {
  text-decoration: underline;
  text-decoration-color: var(--cor-primaria);
  text-align: center;
}

.h-footer {
  width: 100%;
  background-color: var(--cor-primaria);
  padding: 20px;
}

.h-footer-container {
  max-width: 1200px;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.h-footer-container>img {
  width: 150px;
  object-fit: contain;
  margin-top: 50px;
}

.h-footer-container>section {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.h-footer-container>section>div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.h-footer-container>section>div:nth-child(2) {
  text-align: right;
}

.h-footer-container>section>div>a {
  font-size: 17px;
  color: white;
}

@media screen and (max-width: 768px) {
  .h-header-container>nav>a {
    display: none;
  }

  .h-header-container>nav>button {
    display: block;
  }

  .h-header-container>nav>button>i {
    color: var(--cor-primaria);
  }

  .h-sobre-container {
    flex-wrap: wrap;
    padding: 10px;
  }


  .h-sobre-container>div {
    width: 100%;
  }

  .h-func-container>div {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .h-func-container>div>img {
    width: 50%;
  }

  .h-func-container>div {
    flex-direction: column;
    text-align: center;
  }

  .h-func-container>div>p {
    width: 100%;
    padding: 30px 0;
    text-align: center;
  }

  .h-func-container>div:nth-child(2)>p {
    margin: 0;
  }

  .h-depo-container {
    flex-direction: column;
  }

  .h-depo-container>div {
    width: 100%;
  }

  .h-faq-container {
    padding: 30px 0;
  }

  .h-faq-container>h2 {
    text-align: center;
  }

  .h-footer-container>section {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
  }
}